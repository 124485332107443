<template>
  <div v-if="isInvestor && Company.investorCompanyType < 2 && !loading">
    <div
      class="banner-bottom"
      :style="drawer && gs.isMobile() ? 'display: none' : ''"
      v-if="!dialog"
      data-test="Pipeline:AllocationBanner:Banner-Bottom"
    >
      <v-row class="text-part" cols="6" md="8">
        <div v-if="Company.investorCompanyType == 0">
          <div v-if="Company.reservedQuotas === 100">
            <p class="dark-color">
              {{ $t("allocation_under_analysis") }}
            </p>
            <p
              :class="gs.isMobile() ? 'mt-2' : ''"
              style="color: var(--primary); font-weight: 700"
            >
              {{
                $t("reserved_quotas_briefing", {
                  value: Company.reservedQuotas,
                })
              }}
            </p>
          </div>
          <div v-else-if="last == null || last.Status == 2">
            <p class="dark-color">
              {{ $t("reserve_allocation") }}
            </p>
            <p
              v-if="Company.reservedQuotas > 0"
              :class="gs.isMobile() ? 'mt-2' : ''"
              style="color: var(--primary); font-weight: 700"
            >
              {{
                $t("reserved_quotas_briefing", {
                  value: Company.reservedQuotas,
                })
              }}
            </p>
          </div>
          <div
            v-else-if="last.Status == 1"
            data-test="Pipeline:AllocateBanner:PreviousAllocationConfirmed"
          >
            <p class="dark-color">
              {{
                $t("allocation_confirmed", {
                  value: formatToCurrency(last.Currency, last.Value),
                })
              }}
            </p>
          </div>
          <div
            v-else
            data-test="Pipeline:AllocateBanner:PreviousAllocationPending"
          >
            <p v-if="last.Status == 3" class="dark-color">
              {{
                $t("allocation_approval_pending", {
                  value: formatToCurrency(last.Currency, last.Value),
                })
              }}
            </p>
            <p v-else class="dark-color">
              {{
                $t("allocation_pending", {
                  value: formatToCurrency(last.Currency, last.Value),
                })
              }}
            </p>
          </div>
        </div>
        <div data-test="Pipeline:AllocationBanner:OutOfPreferences" v-else>
          <p v-if="notifySent">
            {{ $t("team_notified") }}
          </p>
          <p v-else>
            {{ $t("not_highlight_banner") }}
          </p>
        </div>
      </v-row>
      <v-row
        class="btn-part"
        v-if="Company.reservedQuotas != 100"
        cols="6"
        md="4"
      >
        <v-btn v-if="last && last.Status == 3" text @click="approvals">
          <p
            :style="gs.isMobile() ? 'font-size: 11px;' : ''"
            class="textButton"
            style="
              color: #7733ff;
              text-decoration: underline;
              font-weight: bold;
            "
          >
            {{ $t("approvals") }}
          </p>
        </v-btn>
        <v-btn v-else text @click="investor_dashboard">
          <p
            :style="gs.isMobile() ? 'font-size: 11px;' : ''"
            class="mb-0"
            style="
              color: var(--primary);
              text-decoration: underline;
              font-weight: bold;
            "
          >
            {{ $t("see_allocations") }}
          </p>
        </v-btn>
        <v-btn
          class="dxa-btn-primary mr-0"
          @click="handleInvestorGoCompleteProfile($route.path)"
          v-if="!Company.briefing.preDeal && (user.ProfileDone == false || !checkComplyCube)"
          data-test="Alpha:AllocateBanner:BtnCompleteRegistration"
        >
          {{ $t("complete_registration") }}
        </v-btn>
        <v-btn
          class="dxa-btn-primary mr-0"
          @click="book"
          v-else-if="Company.investorCompanyType == 0"
          data-test="Pipeline:AllocationBanner:BtnNewAllocation"
        >
          {{ $t(last == null ? "allocate" : "new_allocation") }}
        </v-btn>
        <v-btn
          @click="notify_team"
          class="dxa-btn-primary mr-0"
          :loading="notifyLoading"
          v-else-if="Company.investorCompanyType == 1 && !notifySent"
        >
          {{ $t("notify_team") }}
        </v-btn>
      </v-row>
    </div>
    <v-dialog v-if="dialog" v-model="dialog" scrollable>
      <BriefingBookingConfirmationModal
        :InvestmentProxySigned="investmentProxySigned"
        :Investor="investor"
        :Company="Company"
        :Value="Value"
        :Notification="notification"
        @close="reset_dialog"
        @add="add_last"
      ></BriefingBookingConfirmationModal>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import BriefingBookingConfirmationModal from "./BookingConfirmationModal";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { handleInvestorGoCompleteProfile } from "@/shared/helpers/completeProfile";

export default {
  name: "AllocateBanner",

  components: {
    BriefingBookingConfirmationModal,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    dialog: false,
    interests: [],
    investor: null,
    last: null,
    notifyLoading: false,
    investmentProxySigned: false,
    isInvestor: false,
    notifySent: false,
    notification: null,
    user: {},
    formatToCurrency: formatCurrency,
    handleInvestorGoCompleteProfile,
    checkComplyCube: true,
  }),
  props: {
    Company: Object,
    Modal: Boolean,
    Value: Number,
  },

  async created() {
    if (this.Modal) {
      this.dialog = true;
    }
    if (this.$route.query.notification != null) {
      await this.get_notification();
    }
    this.notifySent = this.Company.preferenceChangeSent;
    this.loading = true;

    this.apiService
      .getRequest("user/profile")
      .then((resp) => {
        this.user = JSON.parse(resp);
      })
      .catch((error) => {});

    var user = JSON.parse(localStorage.getItem("user"));
    if (user.type == UserTypeEnum.Investor) {
      this.isInvestor = true;
      this.get_user_interests();
      await this.getComplyCubePendingIssues();
    }
    var user_type = user.type;
    if (user_type == UserTypeEnum.Admin || user.signed_nda) {
      this.signed_nda = true;
    }
  },
  mounted() {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
  methods: {
    async getComplyCubePendingIssues() {
      const user = JSON.parse(localStorage.getItem("user"));

      await this.apiService
        .getRequest(`backgroundcheck/checks-pending/${user.id}`)
        .then((resp) => {
          const complyCubeChecks = resp.content;

          if (complyCubeChecks && complyCubeChecks.length > 0) {
            this.checkComplyCube = false;
          } else {
            this.checkComplyCube = true;
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    approvals: function () {
      this.$router.push({
        path: `/signature-notifications/received`,
        query: { allocations: true },
      });
    },
    investor_dashboard: function () {
      this.$router.push({
        path: `/dashboard/investor`,
        query: { allocations: true },
      });
    },
    add_last(last) {
      this.last = last;
      this.investmentProxySigned = true;
      this.dialog = false;
      this.notification = null;
    },
    async get_notification() {
      await this.apiService
        .getRequest(
          "signaturenotification/get/" + this.$route.query.notification
        )
        .then((resp) => {
          this.notification = JSON.parse(resp.message);
          this.dialog = true;
        });
      if (this.notification && this.$route.query.responseText) {
        this.notification.ResponseText = this.$route.query.responseText;
      }
    },
    async get_user_interests() {
      this.loading = true;
      await this.apiService
        .getRequest("companyinterest/company-list/" + this.Company.companyId)
        .then((resp) => {
          resp = JSON.parse(resp.message);
          this.interests = resp.Interests;
          this.investor = resp.Investor;
          if (this.interests.length > 0) {
            this.investmentProxySigned = resp.InvestmentProxySigned;
            this.last = this.interests[this.interests.length - 1];
          }
        });
      this.loading = false;
    },
    book: function () {
      const user = localStorage.getItem("user");
      const signContractLater = localStorage.getItem("signContractLater");
      const parsedUser = JSON.parse(user);
      if (parsedUser.aYearWithoutInvestments && signContractLater) {
        this.$router.push("/renewContracts");
      } else {
        this.dialog = true;
      }
    },
    notify_team: function () {
      this.notifyLoading = true;
      this.apiService
        .postRequest(
          "userpreferencechange/notify-team/" + this.Company.companyId
        )
        .then((resp) => {
          this.notifySent = true;
          this.notifyLoading = false;
        })
        .catch((error) => {
          this.notifyLoading = false;
        });
    },
    reset_dialog() {
      this.dialog = false;
    },
  },
};
</script>
