<template>
  <v-card
    style="position: relative; min-width: 40%; max-width: 530px"
    class="mx-auto"
  >
    <v-icon
      @click="$emit('close')"
      style="position: absolute; right: 10px; top: 10px"
      :color="last == null && step == 1 && 'primary'"
      >mdi-close</v-icon
    >
    <!-- <v-card-title>
      <h4 class="dxa_modal_title h4">{{ $t("booking_confirmation") }}</h4>
    </v-card-title> -->
    <v-card-text>
      <div v-if="!allowed && !Company.briefing.preDeal">
        <h5
          v-html="$t('booking_modal_text', { name: user.fullName })"
          class="text-center h5 mb-3"
        ></h5>
        <v-card-actions>
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            @click="$router.push('/profile/complete')"
            >{{ $t("complete_registration") }}</v-btn
          >
        </v-card-actions>
      </div>
      <div v-else-if="kyc && kyc.result == 2">
        <h5 class="text-center h5 mb-3">
          {{ $t("bc_rejected") }}
        </h5>
        <v-card-actions>
          <v-btn
            class="dxa_modal_btnSuccess"
            color="red"
            min-width="100"
            @click="$emit('close')"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </div>
      <div v-else-if="last == null && step == 1">
        <h5 class="text-center h5 confirm-request-allocation-title">
          {{ $t("booking_conf_title") }}
        </h5>
        <v-form
          @submit.stop.prevent="confirm_value"
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <div class="confirm-request-allocation-grid">
            <div class="confirm-request-allocation-select-currency-container">
              <span class="confirm-request-allocation-label">{{
                $t("currency")
              }}</span>
              <v-select
                :rules="[required]"
                v-model="currency"
                :items="currencyOptions"
                item-text="text"
                item-value="value"
                outlined
                dense
                color="primary"
                data-test="Pipeline:BookingConfirmation:SelectCurrency"
                @change="get_min_allocation_value"
                class="my-input-style"
              ></v-select>
            </div>
            <div class="confirm-request-allocation-money-currency">
              <span class="confirm-request-allocation-label">{{
                $t("amount")
              }}</span>
              <money
                :rules="[(v) => v >= allocationMinValue || required()]"
                color="primary"
                dense
                outlined
                thousands="."
                decimal=","
                class="money-input my-input-style"
                v-model="value"
                @change.native="showAlertMessageMinValue()"
                data-test="Pipeline:BookingConfirmation:InputAllocationValue"
                :data-min-value="`${allocationMinValue}`"
                style="color: var(--dark)"
              >
              </money>
              <span
                v-if="showNotEnoughMessage"
                class="min-value-required-message"
                data-test="Pipeline:BookingConfirmation:MsgNotEnough"
                >{{
                  $t("not_enough", {
                    value: formatCurrency(currency, allocationMinValue),
                  })
                }}</span
              >
            </div>
          </div>

          <!-- select personId -->
          <div class="person-options-container">
            <div class="content">
              <span class="confirm-request-allocation-label">{{
                $t("investment_desire")
              }}</span>
              <v-select
                placeholder="Selecionar"
                :rules="[required]"
                v-model="personId"
                :items="personOptions"
                item-text="tradingName"
                item-value="id"
                outlined
                dense
                color="primary"
                class="my-input-style"
                data-test="Pipeline:BookingConfirmation:SelectPersonOption"
              ></v-select>
            </div>
          </div>

          <div class="confirm-allocation-min-value-content">
            <p v-if="allocationMinValue > 0">
              {{
                $t("confirm_allocation_min_value", {
                  minValue: formatCurrency(currency, allocationMinValue),
                })
              }}
            </p>
            <p>
              {{ $t("confirm_allocation_min_value_text") }}
            </p>
          </div>
          <v-btn
            :loading="loading"
            type="submit"
            id="confirm-allocation-button-submit"
            data-test="Pipeline:BookingConfirmation:BtnConfirmValue"
          >
            {{ $t("confirm_allocation_button_title") }}
          </v-btn>
        </v-form>
      </div>
      <div v-else-if="last == null && step == 2">
        <v-form
          @submit.stop.prevent="next_step"
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <h5 v-if=Company.briefing.preDeal class="text-center h5 mb-3 dark-color">
            {{ $t("letter_of_intent") }}
          </h5>
          <h5 v-else class="text-center h5 mb-3 dark-color">
            {{ $t("commitment_term") }}
          </h5>
          <div
            style="max-height: 310px; overflow: auto"
            v-if="Company.commitmentTermText != null"
          >
            <p
              class="dark-color"
              style="color: var(--dark)"
              v-html="
                (gs.isJson(Company.commitmentTermText)
                  ? JSON.parse(Company.commitmentTermText)[$i18n.locale]
                  : Company.commitmentTermText
                ).replace('${Valor}', formatCurrency(currency, value))
              "
            ></p>
          </div>
          <v-checkbox
            v-model="terms_agreed"
            :rules="[required]"
            data-test="Notification:BookingModal:CheckboxAgree"
          >
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("agree_terms") }}</span>
            </template>
          </v-checkbox>
          <v-card-actions>
            <v-btn
              class="dxa_modal_btnSuccess mx-auto"
              color="secondary"
              :loading="loading"
              min-width="100"
              type="submit"
              data-test="Notification:BookingModal:BtnConfirm"
              >{{ $t("allocate") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </div>
      <div v-else-if="!InvestmentProxySigned && step == 3 && !Company.briefing.preDeal">
        <h5 class="text-center h5 mb-3">
          {{ $t("investment_proxy") }}
        </h5>
        <p class="mx-6">
          {{ $t("investment_proxy_text") }}
        </p>
        <v-card-actions>
          <v-btn
            class="dxa_modal_btnSuccess mx-auto"
            color="secondary"
            outlined
            :loading="loading"
            min-width="100"
            @click="getProxy"
            data-test="Pipeline:BookingConfirmation:BtnNextProxy"
            >{{ $t("next") }}</v-btn
          >
        </v-card-actions>
      </div>
      <div v-else-if="!InvestmentProxySigned && step == 4 && !Company.briefing.preDeal">
        <v-form
          @submit.stop.prevent="book"
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <h5 class="text-center h5 mb-3">
            {{ $t("investment_proxy") }}
          </h5>
          <div style="max-height: 310px; overflow: auto">
            <p class="dark-color">
              <span
                v-if="
                  Investor.JuridicalType == JuridicalTypeEnum.NaturalPerson &&
                  Investor.Nationality != null &&
                  Investor.Nationality != 'Brazil'
                "
                >GRANTOR: {{ Investor.Name + " " + Investor.LastName }},
                {{ Investor.Nationality }},
                {{ $t(maritalStatus[Investor.MaritalStatus]) }},
                {{ gs.convert_date(Investor.Birth) }},
                {{ Investor.Profession }}, bearer of identity card
                {{ Investor.DocumentNumber }}, issued by the
                {{ Investor.Issuer }}/{{ Investor.IssuerState }}, registered
                with the Passaport under No. {{ Investor.SocialNumber }},
                resident at {{ Investor.Address }}, No.
                {{ Investor.AddressNumber }}, district
                {{ Investor.Neighborhood }}, in {{ Investor.City }}/{{
                  Investor.State
                }}, Zip code {{ Investor.PostCode }}.</span
              >
              <span
                v-else-if="
                  Investor.JuridicalType == JuridicalTypeEnum.NaturalPerson
                "
                >OUTORGANTE: {{ Investor.Name + " " + Investor.LastName }},
                {{ Investor.Nationality }},
                {{ $t(maritalStatus[Investor.MaritalStatus]) }},
                {{ gs.convert_date(Investor.Birth) }},
                {{ Investor.Profession }}, portador da cédula de identidade
                {{ Investor.DocumentNumber }}, expedida pelo
                {{ Investor.Issuer }}/{{ Investor.IssuerState }}, inscrito no
                CPF sob o nº {{ Investor.SocialNumber }}, residente e
                domiciliado na {{ Investor.Address }}, nº
                {{ Investor.AddressNumber }}, bairro
                {{ Investor.Neighborhood }}, em {{ Investor.City }}/{{
                  Investor.State
                }}, CEP {{ Investor.PostCode }}.</span
              >
              <span v-else
                >OUTORGANTE: {{ Investor.CompanyName }}, inscrita no CNPJ sob o
                nº {{ Investor.SocialNumber }}, com sede na
                {{ Investor.Address }}, nº {{ Investor.AddressNumber }}, bairro
                {{ Investor.Neighborhood }}, em {{ Investor.City }}/{{
                  Investor.State
                }}, CEP {{ Investor.PostCode }}, neste ato representada na forma
                de seus atos constitutivos.
              </span>
            </p>
            <p
              class="dark-color"
              v-if="
                Investor.JuridicalType == JuridicalTypeEnum.NaturalPerson &&
                Company.investmentProxyPFText
              "
              v-html="
                gs.isJson(Company.investmentProxyPFText)
                  ? JSON.parse(Company.investmentProxyPFText)[$i18n.locale]
                  : Company.investmentProxyPFText
              "
            ></p>
            <p
              class="dark-color"
              v-else-if="Company.investmentProxyPJText"
              v-html="
                gs.isJson(Company.investmentProxyPJText)
                  ? JSON.parse(Company.investmentProxyPJText)[$i18n.locale]
                  : Company.investmentProxyPJText
              "
            ></p>
          </div>
          <v-checkbox
            v-model="investment_agreed"
            :rules="[required]"
            data-test="Pipeline:BookingConfirmation:BtnAgreeWithProxy"
          >
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("agree_terms") }}</span>
            </template>
          </v-checkbox>
          <v-card-actions>
            <v-btn
              class="dxa_modal_btnSuccess mx-auto"
              color="secondary"
              :loading="loading"
              min-width="100"
              type="submit"
              data-test="Pipeline:BookingConfirmation:BtnSignProxy"
              >{{ $t("sign_investment_proxy") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </div>
      <!-- <div v-else>
        <h5 class="text-center h5 mb-3">
          {{ $t("allocation_pending", { value: last.Value }) }}
        </h5>
        <v-card-actions>
          <v-btn
            class="dxa_modal_btnError"
            color="primary"
            min-width="100"
            @click="$emit('close', null)"
            >{{ $t("go_back") }}</v-btn
          >
        </v-card-actions>
      </div> -->
    </v-card-text>
  </v-card>
</template>

<style scoped>
/* animations */
@keyframes anime-left {
  from {
    opacity: 0;
    transform: translate3d(-30px, 0px, 0px);
  }
  to {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}
/* - */

.my-input-style >>> .v-select__slot input,
.my-input-style >>> .v-select__slot span,
.my-input-style >>> .v-text-field__slot input,
.my-input-style >>> div.v-select__slot div.v-select__selections div,
.my-input-style
  >>> div.v-select__slot
  div.v-input__append-inner
  div.v-input__icon
  i {
  color: var(--dark) !important;
}

.money-input {
  display: block;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 8px;
  color: #495057;
  height: 40px;
  margin: 0;
  width: 100%;
}

.person-options-container {
  margin-left: auto;
  margin-right: auto;
}

.person-options-container {
  width: 80%;
}

.money-input:focus {
  border: 2px solid var(--primary) !important;
  outline: none;
}

.confirm-request-allocation-money-currency {
  width: 80%;
}

.confirm-request-allocation-select-currency-container {
  width: 20%;
}

.confirm-request-allocation-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  text-align: center;
  margin-bottom: 28px;
}

.confirm-request-allocation-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.confirm-request-allocation-grid {
  display: flex;
  gap: 20px;
  margin: 0;
  width: 80%;
  margin: 0 auto;
}

.confirm-allocation-min-value-content > p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  margin: 0;
  text-align: center;
}

.confirm-allocation-min-value-content {
  margin-top: 8px;
  margin-bottom: 32px;
}

#confirm-allocation-button-submit {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--white);
  background: var(--primary);
  border-radius: 8px;
  padding: 12px 20px;
  box-shadow: none;
  width: 100%;
}

.min-value-required-message {
  font-size: 13px;
  display: block;
  color: red;
  margin-top: 4px;
  transition: 0.4s;
  animation: anime-left 0.4s forwards;
  line-height: 1.5;
}

@media only screen and (max-width: 769px) {
  .confirm-request-allocation-grid {
    width: 100%;
  }

  .confirm-request-allocation-money-currency {
    width: 100%;
  }

  .confirm-request-allocation-select-currency-container {
    width: 30%;
  }

  .confirm-allocation-min-value-content > p,
  .min-value-required-message {
    font-size: 12px;
  }
}

@media only screen and (max-width: 320px) {
  .confirm-request-allocation-select-currency-container {
    width: 35%;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";
import { InvestIntendedPersonTypeEnum } from "@/shared/enums/InvestIntendedPersonTypeEnum";
import { removeFormatCnpj, formatCnpj } from "@/shared/helpers/cnpjHelper";

export default {
  name: "BriefingBookingConfirmationModal",
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    value: 0,
    last: null,
    valid: true,
    lazy: false,
    user: null,
    terms_agreed: false,
    investment_agreed: false,
    step: 1,
    kyc: null,
    allowed: true,
    currency: CurrencyEnum.BRL,
    CurrencyEnum: CurrencyEnum,
    formatCurrency: formatCurrency,
    InvestIntendedPersonTypeEnum,
    formatCnpj,
    personId: null,
    personOptions: [],
    currencyOptions: [
      {
        text: "R$",
        value: CurrencyEnum.BRL,
      },
      {
        text: "US$",
        value: CurrencyEnum.USD,
      },
    ],
    showNotEnoughMessage: false,
    minValueOnSubmitNotEnough: false,
    JuridicalTypeEnum,
    allocationMinValue: null,
  }),

  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    await this.get_min_allocation_value();
    await this.getInvestorIntendedPersonOptions();
    if (this.Value && this.Value > 0) {
      this.value = this.Value;
      this.$router.push({ query: { value: undefined } });
    }
    if (!this.user.documentNumber) {
      this.allowed = false;
    } else {
      await this.get_investor_kyc_info();
    }
    if (this.Notification != null) {
      this.value = this.Notification.CompanyInterest.Value;
      this.step = 2;
      this.currency = this.Notification.CompanyInterest.Currency;
    }
    this.last = null;
    if (this.Investor.CurrencyPreference) {
      this.currency = this.Investor.CurrencyPreference;
    }
  },
  computed: {
    maritalStatus() {
      return ["single", "married", "widower", "divorced"];
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    positive() {
      return (value) => parseInt(value) > 0 || this.$t("positive_number");
    },
  },
  methods: {
    showAlertMessageMinValue() {
      // só chama o method ao change quando já tiver ocorrido o submit para não mostrar o erro a cada change no campo
      if (this.minValueOnSubmitNotEnough) {
        if (this.value >= this.allocationMinValue) {
          this.showNotEnoughMessage = false;
        } else {
          this.showNotEnoughMessage = true;
        }
      }
    },
    async getInvestorIntendedPersonOptions() {
      await this.apiService
        .getRequest(`investor/shareholder`)
        .then(async (resp) => {
          const personPF = await resp.content.find((person) => {
            return (
              person.investIntendedPersonId ===
              this.InvestIntendedPersonTypeEnum.PF
            );
          });

          // só quando existe um PersonPF na array. criamos essa lógica para colocar o tradingName da pessoa física.
          if (personPF) {
            const personPFLabel = {
              id: personPF.id,
              investIntendedPersonId: personPF.investIntendedPersonId,
              tradingName: this.$t("fisical_person"),
            };

            // quando só vier pessoa física na array.
            if (resp && resp.content && resp.content.length === 1) {
              this.personOptions = [personPFLabel];
              return;
            }

            // length > 1, ele adicionou algum CNPJ.
            if (resp && resp.content && resp.content.length > 1) {
              const onlyPj = resp.content.filter((person) => {
                let personObject = person;

                if (
                  person.investIntendedPersonId ===
                  this.InvestIntendedPersonTypeEnum.PJ
                ) {
                  if (!person.tradingName) {
                    personObject.tradingName = this.formatCnpj(
                      person.socialNumberOfInvestmentVehicle
                    );
                  }

                  return personObject;
                }
              });

              this.personOptions = [personPFLabel, ...onlyPj];
            }
          }
          // se não tem PF, todos tem tradingName, então não precisa de tratamento.
          else {
            if (resp && resp.content && resp.content.length > 0) {
              this.personOptions = resp.content.map((person) => {
                let personObject = person;

                if (!person.tradingName) {
                  personObject.tradingName = this.formatCnpj(
                    person.socialNumberOfInvestmentVehicle
                  );
                }

                return personObject;
              });
            }
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async get_min_allocation_value() {
      this.loading = true;

      await this.apiService
        .getRequest(
          `company/min-allocation-value-new/${this.Company.companyId}/${this.currency}`
        )
        .then((resp) => {
          this.allocationMinValue = resp.content ? resp.content.minValue : 0;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.loading = false;
    },
    async confirm_value() {
      let result = this.$refs.form.validate();

      if (this.value > 0 && this.value >= this.allocationMinValue) {
        if (result) {
          this.step++;
        }

        this.showNotEnoughMessage = false;
        this.minValueOnSubmitNotEnough = false;

        await this.getCommitmentTerm();
      } else {
        this.minValueOnSubmitNotEnough = true;
        this.showNotEnoughMessage = true;
      }
    },
    async getCommitmentTerm() {
      if (this.Company.commitmentTermText) return;

      this.loading = true;

      await this.apiService
        .getRequest(
          "companybriefing/commitment-term/" + this.Company.briefing.id
        )
        .then((resp) => {
          this.Company.commitmentTermText = resp.content.commitmentTermText;
        });

      this.loading = false;
    },
    async getProxy() {
      this.step++;

      if (
        this.Company.investmentProxyPFText &&
        this.Company.investmentProxyPJText
      )
        return;

      this.loading = true;

      await this.apiService
        .getRequest("companybriefing/proxy/" + this.Company.briefing.id)
        .then((resp) => {
          this.Company.investmentProxyPFText =
            resp.content.investmentProxyPFText;
          this.Company.investmentProxyPJText =
            resp.content.investmentProxyPJText;
        })
        .catch(() => {
          this.Company.investmentProxyPFText = null;
          this.Company.investmentProxyPJText = null;
        });

      this.loading = false;
    },
    async get_investor_kyc_info() {
      if (this.user.nationality && this.user.nationality != "Brazil") {
        this.kyc = {
          result: 0,
        };
        return;
      }
      await this.apiService
        .getRequest("backgroundcheck/status")
        .then((resp) => {
          this.kyc = JSON.parse(resp.message);
        })
        .catch((error) => {
          if (error.body.message == "investor_dsnt_exist") {
            this.$toast.error(this.$t("investor_dsnt_exist"));
          } else if (error.body.message.includes("missing")) {
            this.$router.push({ path: "/profile" });
            this.$toast.error(this.$t(error.body.message));
          }
        });
    },
    next_step() {
      var result = this.$refs.form.validate();    
      if (result) {
        if (this.step == 2 && (this.InvestmentProxySigned || this.Company.briefing.preDeal)) {
          this.book();
        } else {
          this.step++;
        }
      }
    },
    book: async function () {
      var result = this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.loading = true;
      await this.apiService
        .postRequest("companyinterest/book", {
          CompanyId: this.Company.companyId,
          Value: parseFloat(this.value),
          Language: this.$i18n.locale,
          NotificationId: this.Notification != null ? this.Notification.Id : 0,
          Currency: this.currency,
          InvestorInvestIntendedPersonId: this.personId,
        })
        .then((resp) => {
          this.last = JSON.parse(resp.message);
          this.$router.replace({
            query: { responseText: undefined, notification: undefined },
          });
          this.$emit("add", this.last);
        })
        .catch((error) => {
          if (error.body.message == "complete_profile_alert") {
            this.allowed = false;
          }
        });
      this.loading = false;
    },
  },
  props: {
    Investor: Object,
    Company: Object,
    InvestmentProxySigned: Boolean,
    Value: Number,
    Notification: Object,
  },
};
</script>
