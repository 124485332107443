<template>
  <div>
    <div class="v-carousel-header">
      <span style="color: var(--white)"
        >{{ model + 1 }}/{{ Slides.length }}</span
      >
    </div>
    <v-carousel v-model="model" hide-delimiters height="100%">
      <v-carousel-item v-for="(slide, i) in Slides" :key="i">
        <v-img :src="slide"></v-img>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<style lang="scss" scoped>
.v-carousel-header {
  background-color: var(--primary);
  display: grid;
  place-items: center;
}
</style>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
@import "@/sass/variables.scss";
</style>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "FullscreenPresentationDialog",
  props: {
    Slides: Array,
  },
  components: {
    // Swiper,
    // SwiperSlide,
  },
  data: () => ({
    apiService: new ApiService(),
    model: 0,
  }),

  mounted() {},
  async created() {},
  computed: {},
  methods: {
    get_image_path(path) {
      return path;
      // return "https://dxainvestapi.azurewebsites.net/" + path;
    },
  },
};
</script>

<style scoped>
.v-carousel-header {
  background-color: var(--primary);
  display: grid;
  place-items: center;
}
</style>
