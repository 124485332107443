import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    !_vm.loading &&
    _vm.analysis.filter(
      function (x) { return x.type == 0 && _vm.gs.check_field(x.title) && _vm.gs.check_field(x.text); }
    ).length > 0
  )?_c(VContainer,{staticClass:"dxa-briefing-card mt-4",attrs:{"id":"overall","fluid":"","tag":"section"}},[_c('div',{staticClass:"ma-5"},[_c('h4',{staticClass:"dxa-briefing-section-header h4"},[_vm._v(" "+_vm._s(_vm.$t("company_analysis"))+" ")]),_vm._l((_vm.analysis.filter(
        function (x) { return x.type == 0 && _vm.gs.check_field(x.title) && _vm.gs.check_field(x.text); }
      )),function(item,index){return _c('tr',{key:index},[_c('div',{staticClass:"mt-3"},[_c('span',{staticStyle:{"color":"var(--primary)","font-size":"1.2rem"}},[_vm._v(_vm._s(_vm.gs.check_field(item.title))+":")]),_c('span',{staticClass:"ml-2",staticStyle:{"font-size":"1.2rem"}},[_vm._v(_vm._s(_vm.gs.check_field(item.text)))])])])})],2)]):(_vm.loading)?_c(VContainer,{staticClass:"white rounded mt-4",attrs:{"fluid":"","tag":"section"}},[_c('div',{staticClass:"ma-5"},[_c('h4',{staticClass:"text-center h4",staticStyle:{"color":"#006364"}},[_vm._v(" "+_vm._s(_vm.$t("company_analysis"))+" ")])]),_c(VProgressCircular,{staticStyle:{"margin":"0 auto","display":"block"},attrs:{"indeterminate":"","size":"70","color":"primary"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }