<template>
  <v-container
    v-if="
      investmentConditions.intro ||
      (investmentConditions.text && investmentConditions.text != '<p><br></p>')
    "
    class="dxa-briefing-card mt-4"
    id="overall"
    fluid
    tag="section"
  >
    <h4 class="dxa-briefing-section-header h4">
      {{ $t("about_thesis") }}
    </h4>
    <v-row class="mt-4">
      <v-col v-if="investmentConditions.intro" cols="12" :md="12" class="pr-15">
        <div
          v-html="investmentConditions.intro"
          style="font-size: 1.2rem; color: var(--dark)"
        ></div>
      </v-col>
      <v-col v-if="investmentConditions.text" cols="12" :md="12">
        <span
          class="text dark-color"
          id="InvestmentConditionsText"
          v-bind:class="{ 'text-expanded': expanded }"
          v-html="investmentConditions.text"
          style="font-size: 1.1rem"
        >
        </span>
        <div
          v-if="read_more_visible"
          class="mt-4 read-more-link"
          style="text-decoration: underline"
        >
          <a v-if="expanded == false" @click="expanded = true">{{
            $t("read_more")
          }}</a>
          <a v-else @click="expanded = false">{{ $t("read_less") }}</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
.text {
  display: block;
  max-height: 25rem;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
}
.text-expanded {
  max-height: 100% !important;
}

.read-more-link {
  padding-bottom: 40px;
}

@media only screen and (min-width: 960px) {
  .text-columns {
    //column-count: 2;
    column-gap: 40px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import { LanguageEnum } from "@/shared/enums/LanguageEnum";
export default {
  name: "BriefingInvestmentConditions",
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    expanded: false,
    read_more_visible: false,
    investmentConditions: {
      id: null,
      text: null,
      intro: null,
    },
    LanguageEnum
  }),

  async created() {
    this.getInvestmentConditions();
  },
  mounted() {
    this.check_span_size();
  },
  computed: {},
  watch: {
    "$i18n.locale"() {
      this.getInvestmentConditions();
    },
  },
  methods: {
    convertRemToPixels(rem) {
      return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
    },
    check_span_size() {
      var span = document.getElementById("InvestmentConditionsText");
      if (
        span &&
        this.investmentConditions.text != null &&
        this.investmentConditions.text != ""
      ) {
        var span_height = parseFloat(
          window.getComputedStyle(span).height.replace("px", "")
        );
        this.read_more_visible =
          span_height >= this.convertRemToPixels(25) - 0.1;
        return this.read_more_visible;
      }
    },
    getInvestmentConditions: async function () {
      await this.apiService
        .getRequest(
          `companybriefing/investment-conditions/${this.BriefingId}/${this.LanguageEnum[this.$i18n.locale]}/${this.PartnerB2bId ?? 0}`,
          {
            defaultPartner: true,
          }
        )
        .then((res) => {
          this.investmentConditions.intro =
            res.content?.investmentConditionsIntro;
          this.investmentConditions.text =
            res.content?.investmentConditionsText;
          this.investmentConditions.id = res.content?.id;
        })
        .catch((error) => {
          this.$emit("alert", false, error);
        });
    },
  },
  props: {
    BriefingId: Number,
    PartnerB2bId: Number,
  },
};
</script>
