<template>
  <DXAMediaComponent :Media="media_filtered" :Header="Header" />
</template>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DXAMediaComponent from "@/components/dxa/Briefing/Media/Media";
export default {
  name: "BriefingMedia",
  components: { DXAMediaComponent },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    media: [],
    media_filtered: [],
  }),
  async created() {
    await this.get_media();
  },
  watch: {
    "$i18n.locale"() {
      this.filter_media();
    },
  },
  computed: {},
  props: {
    BriefingId: Number,
    MediaType: Number,
    Header: String,
  },
  methods: {
    async get_media() {
      await this.apiService
        .getRequest(`companymedia/filter`, {
          BriefingId: this.BriefingId,
          Type: this.MediaType,
        })
        .then((resp) => {
          this.media = JSON.parse(resp.message);
          this.filter_media();
        });
    },
    filter_media() {
      this.media_filtered = this.media.filter(
        (x) => this.gs.getLanguage(x.Language) === this.$i18n.locale
      );
    },
  },
};
</script>
