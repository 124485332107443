<template>
  <div class="mx-4">
    <div class="mx-auto text-center">
      <v-avatar class="profile-image">
        <v-img
          style="cursor: pointer"
          v-if="Member.Image != null"
          :src="gs.get_photo_path(Member.Image)"
        ></v-img>
        <v-img
          style="cursor: pointer"
          src="@/assets/profile.png"
          v-else
        ></v-img>
      </v-avatar>
      <p class="pt-2" style="font-size: 1.5rem">
        <b>{{ Member.Name }}</b>
      </p>
      <p style="font-size: 1.4rem">
        {{ gs.check_field(Member.Position) }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile-image {
  height: 11.5rem !important;
  min-width: 11.5rem !important;
  width: 48px !important;
  border-radius: 50% !important;
}
</style>

<script>
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "BriefingTeam",
  data: () => ({
    gs: new GeneralServices(),
  }),

  props: {
    Member: Object,
  },

  async created() {},
  computed: {},
  methods: {},
};
</script>
