<template>
  <div class="embed-container">
    <iframe
      title="Video"
      v-if="Media.Plataform === videoPlatformEnum.VIMEO"
      :src="`https://player.vimeo.com/video/${Media.VideoId}?portrait=0&byline=0&title=0`"
      frameborder="0"
      allowfullscreen
    ></iframe>
    <iframe
      title="Video"
      v-if="Media.Plataform === videoPlatformEnum.YOUTUBE"
      :src="`https://www.youtube.com/embed/${Media.VideoId}?rel=0,showinfo=0,controls=0`"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
</style>

<script>
import GeneralServices from "@/services/GeneralServices";
import { videoPlatformEnum } from "@/shared/enums/VideoPlatformEnum";

export default {
  name: "DXASingleMediaComponent",
  data: () => ({
    gs: new GeneralServices(),
    videoPlatformEnum,
  }),
  async created() {},
  watch: {},
  computed: {},
  props: {
    Media: Object,
  },
  methods: {},
};
</script>
