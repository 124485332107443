import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    !_vm.loading &&
    (_vm.check_financial_overview() ||
      _vm.company.briefing.companyAnalysis.filter(
        function (x) { return x.type == 1 && _vm.gs.check_field(x.title) && _vm.gs.check_field(x.text); }
      ).length > 0)
  )?_c(VContainer,{staticClass:"dxa-briefing-card mt-4",attrs:{"id":"overall","fluid":"","tag":"section"}},[_c('div',[_c(VRow,[(_vm.check_financial_overview())?_c(VCol,{staticClass:"overallColumn",class:_vm.gs.isMobile() ? '' : 'pr-15',attrs:{"cols":"12","md":"8"}},[_c('h4',{staticClass:"dxa-briefing-section-header h4 dark-color"},[_vm._v(" "+_vm._s(_vm.$t("overview"))+" ")]),_c('p',{staticClass:"mt-5 text dark-color",class:{ 'text-expanded': _vm.expanded },staticStyle:{"font-size":"1.2rem"},attrs:{"id":"OverviewText"}},[_vm._v(" "+_vm._s(_vm.company.briefing.financialOverview[_vm.$i18n.locale])+" ")])]):_vm._e(),(_vm.gs.isMobile())?_c(VCol,[_c('div',[(_vm.read_more_visible)?_c('div',{staticClass:"mt-4 ml-4"},[(_vm.expanded == false)?_c('a',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){_vm.expanded = true}}},[_vm._v(_vm._s(_vm.$t("read_more")))]):_c('a',{on:{"click":function($event){_vm.expanded = false}}},[_vm._v(_vm._s(_vm.$t("read_less")))])]):_vm._e()])]):_vm._e(),_c(VCol,{class:{ 'text-expanded': _vm.expanded },style:(("display: " + (_vm.gs.isMobile() ? 'block' : 'flex'))),attrs:{"id":"Fields","cols":"12","md":_vm.check_financial_overview() ? 4 : 12}},[_c(VDivider,{staticStyle:{"width":"100%"},attrs:{"color":"var(--primary)","vertical":!_vm.gs.isMobile()}}),_c('div',{class:_vm.gs.isMobile() ? '' : 'pl-5 wd100'},[(_vm.company.logo != null)?_c(VImg,{class:_vm.check_financial_overview()
                ? 'FinantialLogo'
                : 'FinantialLogoFull',attrs:{"src":_vm.company.logo}}):_vm._e(),_c('div',{staticClass:"text mx-0 mt-10",class:{ 'text-expanded': _vm.expanded },attrs:{"id":"Fields"}},[_c('div',{staticClass:"text-left text my-auto",staticStyle:{"display":"contents"}},_vm._l((_vm.company.briefing.companyAnalysis.filter(
                  function (x) { return x.type == 1 &&
                    _vm.gs.check_field(x.title) &&
                    _vm.gs.check_field(x.text); }
                )),function(item,index){return _c(VRow,{key:index,staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('b',{staticClass:"analysisTitle"},[_c('span',{staticClass:"dark-color",staticStyle:{"font-size":"1.3rem"}},[_vm._v(_vm._s(_vm.gs.check_field(item.title)))])]),_c('p',{staticClass:"analysisText dark-color",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(_vm.gs.check_field(item.text))+" ")])])}),1)])],1)],1)],1),(!_vm.gs.isMobile())?_c(VRow,[_c('div',[(_vm.read_more_visible)?_c('div',{staticClass:"mt-4 ml-4"},[(_vm.expanded == false)?_c('a',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){_vm.expanded = true}}},[_vm._v(_vm._s(_vm.$t("read_more")))]):_c('a',{on:{"click":function($event){_vm.expanded = false}}},[_vm._v(_vm._s(_vm.$t("read_less")))])]):_vm._e()])]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }