<template>
  <v-container
    v-if="!loading && team.length > 0"
    class="dxa-briefing-card mt-4"
    fluid
    tag="section"
  >
    <div>
      <h4 class="dxa-briefing-section-header h4">
        {{ $t("team") }}
      </h4>
      <div v-if="team.length > 0">
        <div
          class="mt-6"
          style="display: inline-flex; overflow: auto; width: 100%"
          v-if="!gs.isMobile()"
        >
          <tr v-for="(member, i) in team" :key="i" class="mx-auto">
            <MemberComponent :Member="member" />
          </tr>
        </div>
        <div v-else>
          <MemberComponent :Member="team[this.index_selected]" />
          <div style="display: flex">
            <DXASelector
              v-for="(member, i) in team"
              :key="i"
              :Checked="index_selected == i"
              @check="index_selected = i"
            />
          </div>
        </div>
      </div>
    </div>
  </v-container>
  <v-container
    class="white rounded mt-4"
    fluid
    tag="section"
    v-else-if="loading"
  >
    <div class="ma-5">
      <h4 class="text-center h4" style="color: #006364">
        {{ $t("team") }}
      </h4>
    </div>
    <v-progress-circular
      style="margin: 0 auto; display: block"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped>
.profileImage {
  height: 11.5rem !important;
  min-width: 11.5rem !important;
  width: 48px !important;
  border-radius: 50% !important;
}
@media only screen and (max-width: 600px) {
  .team_div {
    margin-left: 8px;
    margin-right: 8px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import MemberComponent from "./Member.vue";
import DXASelector from "@/components/dxa/Briefing/Selector";

export default {
  name: "BriefingTeam",
  components: { MemberComponent, DXASelector },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    index_selected: 0,
    team: [],
  }),

  props: {
    BriefingId: Number,
  },

  async created() {
    this.loading = true;
    await this.apiService
      .getRequest(`companyteam/list/${this.BriefingId}`)
      .then((resp) => {
        this.team = JSON.parse(resp.message);
      });
    this.loading = false;
  },
  computed: {},
  methods: {},
};
</script>
