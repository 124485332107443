<template>
  <div class="px-1">
    <div>
      <v-row align="end">
        <v-col cols="12" md="9" order="1" class="pa-2">
          <h2
            class="item-name h2 dark-color"
            data-test="Pipeline:BriefingHeader:ItemName"
          >
            {{ Item.Name }}
          </h2>
        </v-col>
        <v-col
          class="text-right pa-2"
          cols="12"
          md="3"
          style="display: flex; justify-content: end"
          :order="gs.isMobile() ? 2 : 1"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="btn_index primary mr-0"
                style="
                  width: 221px;
                  box-shadow: none !important;
                  border-radius: 15px !important;
                "
                v-bind="attrs"
                v-on="on"
              >
                <div style="text-align: center; width: 160px">
                  <span style="font-size: 18px">
                    {{ $t("index") }}
                  </span>
                </div>
                <div style="text-align: right; width: 30px">
                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                </div>
              </v-btn>
            </template>
            <v-list :tile="false" flat nav>
              <v-list-item-title
                nav
                class="titlelist px-3 py-3"
                style="font-weight: bold"
                v-for="(item, index) in GoToOptions"
                :key="index"
                @click="go_to_click(item)"
                v-text="$t(item.text)"
              />
            </v-list>
          </v-menu>
        </v-col>
        <v-col :order="gs.isMobile() ? 1 : 2" class="pa-2">
          <v-img
            @click="showVideo()"
            v-if="Item.Hero != null"
            :src="gs.get_photo_path(Item.Hero)"
            class="mx-auto heroImage"
          ></v-img>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      class="videoHero"
      max-width="100%"
      v-model="dialog"
      v-if="dialog && SignedNDA"
    >
      <v-container
        style="width: 1500px !important"
        id="add-stock-modal"
        fluid
        tag="section"
      >
        <v-card class="videoHero_modal" style="padding-bottom: 15px">
          <div class="embed-container">
            <iframe
              title="Video Introdução"
              :src="`https://player.vimeo.com/video/${
                Item.IntroVideo[$i18n.locale]
              }?portrait=0&byline=0&title=0`"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
.titlelist:hover {
  background: var(--primary-hover);
  border-radius: 20px !important;
}
.btn_index {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 20px !important;
}

.v-dialog.v-dialog--active {
  width: 80% !important;
}

.heroImage {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(84, 77, 84, 1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(84, 77, 84, 1);
  box-shadow: 0px 0px 3px 0px rgba(84, 77, 84, 1);
}

.item-name {
  @media only screen and (max-width: 600px) {
    font-size: 24px !important;
    line-height: 30px !important;
    letter-spacing: 0.15px !important;
  }
  text-align: left;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
}

.embed-container {
  // --video--width: 630;
  // --video--height: 630 !important;
  padding-top: 0%;
}

.embed-container iframe {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

@media screen and (min-width: 850px) {
  .v-dialog.v-dialog--active {
    width: 70% !important;
    min-width: 70% !important;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "BriefingHeaderComponent",
  data: () => ({
    gs: new GeneralServices(),
    dialog: false,
  }),

  async created() {},
  computed: {},
  methods: {
    go_to_click(option) {
      this.$emit("go_to", option.title);
    },
    showVideo() {
      if (
        this.Item.IntroVideo[this.$i18n.locale] != null &&
        this.Item.IntroVideo[this.$i18n.locale] != ""
      )
        this.dialog = true;
    },
  },
  props: {
    Item: Object,
    SignedNDA: Boolean,
    GoToOptions: Array,
  },
};
</script>
