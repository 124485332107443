<template>
  <v-container
    v-if="Media.length > 0"
    class="dxa-briefing-card mt-4"
    :style="gs.isMobile() ? 'padding: 12px 16px !important' : ''"
    fluid
    tag="section"
  >
    <h4
      class="dxa-briefing-section-header h4"
      :style="gs.isMobile() ? 'padding-left: 12px !important' : ''"
    >
      {{ Header }}
    </h4>
    <div v-if="Media.length > 0" class="pt-5">
      <div class="dxa-briefing-media-row" v-if="!gs.isMobile()">
        <table aria-label="Tabela">
          <tr>
            <td
              v-for="item in Media"
              :key="item.Id"
              class="mx-auto text-center"
            >
              <SingleMedia :Media="item" class="mr-6" />
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        <SingleMedia :Media="Media[index_selected]" />
        <div style="display: flex" class="mt-3">
          <DXASelector
            v-for="(n, i) in Media"
            :key="i"
            :Checked="index_selected == i"
            @check="index_selected = i"
          />
        </div>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
</style>

<script>
import GeneralServices from "@/services/GeneralServices";
import SingleMedia from "./SingleMedia.vue";
import DXASelector from "@/components/dxa/Briefing/Selector";
export default {
  name: "DXAMediaComponent",
  components: { SingleMedia, DXASelector },
  data: () => ({
    gs: new GeneralServices(),
    index_selected: 0,
  }),
  async created() {},
  watch: {},
  computed: {},
  props: {
    Media: Array,
    Header: String,
  },
  methods: {},
};
</script>
