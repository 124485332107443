<template>
  <v-container
    v-if="!loading && news_filtered.length > 0"
    class="dxa-briefing-card mt-4"
    :style="gs.isMobile() ? 'padding: 12px 16px !important' : ''"
    id="market-news"
    fluid
    tag="section"
  >
    <h4
      class="dxa-briefing-section-header h4"
      :style="gs.isMobile() ? 'padding-left: 12px !important' : ''"
    >
      {{ $t("market_news") }}
    </h4>
    <div v-if="news_filtered.length > 0" class="pt-5">
      <div class="dxa-briefing-media-row" v-if="!gs.isMobile()">
        <table>
          <tr>
            <td v-for="(n, i) in news_filtered" :key="i">
              <a @click="redirect_news(n)">
                <img
                  style="
                    object-fit: contain;
                    max-height: 400px;
                    max-width: 450px;
                  "
                  :src="gs.get_photo_path(n.Image)"
                />
              </a>
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        <a @click="redirect_news(news_filtered[index_selected])">
          <img
            style="object-fit: contain; max-width: 100%"
            :src="gs.get_photo_path(news_filtered[index_selected].Image)"
          />
        </a>
        <div style="display: flex">
          <DXASelector
            v-for="(n, i) in news_filtered"
            :key="i"
            :Checked="index_selected == i"
            @check="index_selected = i"
          />
        </div>
      </div>
    </div>
  </v-container>
  <v-container
    class="white rounded mt-4"
    fluid
    tag="section"
    v-else-if="loading"
  >
    <div class="ma-5">
      <h4
        class="dxa-briefing-section-header h4"
        :style="gs.isMobile() ? 'padding-left: 12px !important' : ''"
      >
        {{ $t("market_news") }}
      </h4>
    </div>
    <v-progress-circular
      style="margin: 0 auto; display: block"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DXASelector from "@/components/dxa/Briefing/Selector";
import "swiper/css/swiper.css";
export default {
  name: "BriefingMarketNews",
  components: { DXASelector },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    carousel_index: 0,
    news: [],
    news_filtered: [],
    index_selected: 0,
    swiperOption: {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      pagination: {
        el: ".swiper-pagination",
      },
    },
  }),

  props: {
    BriefingId: Number,
  },

  async created() {
    await this.get_news();
  },
  watch: {
    "$i18n.locale"() {
      this.filter_news();
    },
  },
  computed: {},
  methods: {
    async get_news() {
      this.loading = true;
      await this.apiService
        .getRequest(`companynews/list/${this.BriefingId}`)
        .then((resp) => {
          this.news = JSON.parse(resp.message);
          this.filter_news();
        });
      this.loading = false;
    },
    filter_news() {
      this.news_filtered = this.news.filter(
        (x) => this.gs.getLanguage(x.Language) === this.$i18n.locale
      );
    },
    redirect_news(n) {
      if (n.Ref) {
        var win = window.open(n.Ref, "_blank");
        win.focus();
      }
    },
    carousel_go_back: function () {
      if (this.carousel_index == 0) {
        this.carousel_index = this.news.length - 1;
      } else {
        this.carousel_index--;
      }
    },
  },
};
</script>
