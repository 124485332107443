<template>
  <div style="display: flex">
    <v-btn icon @click="$emit('check')" small>
      <img
        v-if="Checked"
        src="@/assets/briefing_selector_checked.png"
        alt="Icon Checked"
      />
      <img v-else src="@/assets/briefing_selector.png" alt="Icon Checked" />
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "DXASelectorComponent",
  components: {},
  data: () => ({}),
  async created() {},
  props: {
    Checked: Boolean,
  },
};
</script>

<style></style>
