import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.investmentConditions.intro ||
    (_vm.investmentConditions.text && _vm.investmentConditions.text != '<p><br></p>')
  )?_c(VContainer,{staticClass:"dxa-briefing-card mt-4",attrs:{"id":"overall","fluid":"","tag":"section"}},[_c('h4',{staticClass:"dxa-briefing-section-header h4"},[_vm._v(" "+_vm._s(_vm.$t("about_thesis"))+" ")]),_c(VRow,{staticClass:"mt-4"},[(_vm.investmentConditions.intro)?_c(VCol,{staticClass:"pr-15",attrs:{"cols":"12","md":12}},[_c('div',{staticStyle:{"font-size":"1.2rem","color":"var(--dark)"},domProps:{"innerHTML":_vm._s(_vm.investmentConditions.intro)}})]):_vm._e(),(_vm.investmentConditions.text)?_c(VCol,{attrs:{"cols":"12","md":12}},[_c('span',{staticClass:"text dark-color",class:{ 'text-expanded': _vm.expanded },staticStyle:{"font-size":"1.1rem"},attrs:{"id":"InvestmentConditionsText"},domProps:{"innerHTML":_vm._s(_vm.investmentConditions.text)}}),(_vm.read_more_visible)?_c('div',{staticClass:"mt-4 read-more-link",staticStyle:{"text-decoration":"underline"}},[(_vm.expanded == false)?_c('a',{on:{"click":function($event){_vm.expanded = true}}},[_vm._v(_vm._s(_vm.$t("read_more")))]):_c('a',{on:{"click":function($event){_vm.expanded = false}}},[_vm._v(_vm._s(_vm.$t("read_less")))])]):_vm._e()]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }