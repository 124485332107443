<template>
  <v-container
    v-if="Highlights.filter((x) => gs.check_field(x.Text)).length > 0"
    class="dxa-briefing-card mt-4"
    id="overall"
    fluid
    tag="section"
  >
    <h4 class="dxa-briefing-section-header h4 dark-color">
      {{ $t("highlights") }}
    </h4>
    <v-row class="mt-4">
      <v-col
        v-for="item in Highlights.filter((x) => gs.check_field(x.Text))"
        :key="item.Id"
        cols="12"
        md="4"
      >
        <div v-if="!loading" :class="gs.isMobile() ? 'pr-3' : 'pr-12'">
          <span
            :id="`Span-${item.Id}`"
            class="text dark-color"
            v-bind:class="{ 'text-expanded': item.Expanded }"
            style="font-size: 1.1rem"
          >
            <v-avatar class="mr-2 mb-1" color="primary" size="12" />{{
              gs.check_field(item.Text)
            }}</span
          >
          <div
            v-if="item.ReadMoreVisible"
            class="mt-4"
            style="text-decoration: underline"
          >
            <a @click="item.Expanded = !item.Expanded">{{
              $t(item.Expanded ? "read_less" : "read_more")
            }}</a>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.text {
  display: block;
  max-height: 10rem;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
}
.text-expanded {
  max-height: 100% !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "BriefingHighlights",
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    highlights: [],
  }),
  props: {
    Highlights: Array,
  },
  watch: {
    Highlights() {
      this.get_highlights();
    },
  },
  async created() {
    this.get_highlights();
  },
  mounted() {
    this.check_span_size();
  },
  computed: {},
  methods: {
    get_highlights() {
      this.highlights = this.Highlights.filter((x) =>
        this.gs.check_field(x.Text)
      );
    },
    convertRemToPixels(rem) {
      return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
    },
    check_span_size() {
      for (var i = 0; i < this.highlights.length; i++) {
        var span = document.getElementById(`Span-${this.highlights[i].Id}`);
        if (
          span &&
          this.highlights[i].Text != null &&
          this.highlights[i].Text != ""
        ) {
          var span_height = parseFloat(
            window.getComputedStyle(span).height.replace("px", "")
          );
          this.highlights[i].ReadMoreVisible =
            span_height >= this.convertRemToPixels(10) - 0.1;
        }
      }
    },
  },
};
</script>
