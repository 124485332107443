<template>
  <v-container
    v-if="
      !loading &&
      analysis.filter(
        (x) => x.type == 0 && gs.check_field(x.title) && gs.check_field(x.text)
      ).length > 0
    "
    class="dxa-briefing-card mt-4"
    id="overall"
    fluid
    tag="section"
  >
    <div class="ma-5">
      <h4 class="dxa-briefing-section-header h4">
        {{ $t("company_analysis") }}
      </h4>
      <tr
        v-for="(item, index) in analysis.filter(
          (x) =>
            x.type == 0 && gs.check_field(x.title) && gs.check_field(x.text)
        )"
        :key="index"
      >
        <div class="mt-3">
          <span style="color: var(--primary); font-size: 1.2rem"
            >{{ gs.check_field(item.title) }}:</span
          >
          <span class="ml-2" style="font-size: 1.2rem">{{
            gs.check_field(item.text)
          }}</span>
        </div>
      </tr>
    </div>
  </v-container>
  <v-container
    class="white rounded mt-4"
    fluid
    tag="section"
    v-else-if="loading"
  >
    <div class="ma-5">
      <h4 class="text-center h4" style="color: #006364">
        {{ $t("company_analysis") }}
      </h4>
    </div>
    <v-progress-circular
      style="margin: 0 auto; display: block"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "BriefingAnalysis",
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
  }),

  props: {
    analysis: Array,
  },

  async created() {},
  computed: {},
  methods: {},
};
</script>
