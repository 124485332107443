<template>
  <v-container
    v-if="Files.length > 0"
    class="dxa-briefing-card mt-4"
    fluid
    tag="section"
  >
    <h4 class="dxa-briefing-section-header h4">
      {{ $t("files") }}
    </h4>
    <v-row class="mt-4">
      <v-col cols="12" md="6" v-for="item in Files" :key="item.id">
        <div
          class="file-div"
          v-bind:class="{
            blur: loggedUser.type === UserTypeEnum.Investor && !SignedNDA,
          }"
        >
          <p class="file-div-name">{{ item.fileName }}</p>
          <v-spacer></v-spacer>
          <v-icon
            @click="downloadFile(item)"
            v-if="fileDownloading !== item"
            color="secondary"
            class="mr-4"
            >mdi-download</v-icon
          >
          <v-progress-circular
            v-if="fileDownloading == item"
            color="secondary"
            size="16"
            class="mr-4"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
.file-div-name {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-bottom: 0px !important;
  padding: 12px;
}
.file-div {
  border: 1px solid var(--primary);
  box-sizing: border-box;
  border-radius: 9px;
  display: flex;
  align-items: center;
}
.file {
  border-bottom: grey;
  border-bottom-style: solid;
  border-bottom-width: thin;
  width: 100%;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { UserTypeEnum } from "@/shared/enums/UserType.js";

export default {
  name: "BriefingFiles",
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    fileDownloading: null,
    loggedUser: null,
    UserTypeEnum,
  }),

  async created() {
    this.loggedUser = JSON.parse(localStorage.getItem("user"));
  },
  computed: {},
  methods: {
    async downloadFile(file) {
      this.fileDownloading = file;
      await this.apiService
        .getRequest(`${this.downloadUrl}/${file.id}`)
        .then((resp) => {
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", file.fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_occurred"));
        });
      this.fileDownloading = null;
    },
  },
  props: {
    Files: Array,
    SignedNDA: Boolean,
    downloadUrl: String,
  },
};
</script>
