export const formatCnpj = (cnpj) => {
  if (cnpj) {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return "-";
  }
};

export const removeFormatCnpj = (cnpj) => {
  if (cnpj) {
    return cnpj.replace(/\D/g, "");
  } else {
    return "";
  }
};
