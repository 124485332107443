<template>
  <v-container
    v-if="
      !loading &&
      (check_financial_overview() ||
        company.briefing.companyAnalysis.filter(
          (x) =>
            x.type == 1 && gs.check_field(x.title) && gs.check_field(x.text)
        ).length > 0)
    "
    class="dxa-briefing-card mt-4"
    id="overall"
    fluid
    tag="section"
  >
    <div>
      <v-row>
        <v-col
          v-if="check_financial_overview()"
          cols="12"
          md="8"
          class="overallColumn"
          :class="gs.isMobile() ? '' : 'pr-15'"
        >
          <h4 class="dxa-briefing-section-header h4 dark-color">
            {{ $t("overview") }}
          </h4>
          <p
            id="OverviewText"
            class="mt-5 text dark-color"
            v-bind:class="{ 'text-expanded': expanded }"
            style="font-size: 1.2rem"
          >
            {{ company.briefing.financialOverview[$i18n.locale] }}
          </p>
        </v-col>
        <v-col v-if="gs.isMobile()">
          <div>
            <div v-if="read_more_visible" class="mt-4 ml-4">
              <a
                v-if="expanded == false"
                @click="expanded = true"
                style="text-decoration: underline"
                >{{ $t("read_more") }}</a
              >
              <a v-else @click="expanded = false">{{ $t("read_less") }}</a>
            </div>
          </div>
        </v-col>
        <v-col
          id="Fields"
          :style="`display: ${gs.isMobile() ? 'block' : 'flex'}`"
          v-bind:class="{ 'text-expanded': expanded }"
          cols="12"
          :md="check_financial_overview() ? 4 : 12"
        >
          <v-divider
            style="width: 100%"
            color="var(--primary)"
            :vertical="!gs.isMobile()"
          />
          <div :class="gs.isMobile() ? '' : 'pl-5 wd100'">
            <v-img
              v-if="company.logo != null"
              :class="
                check_financial_overview()
                  ? 'FinantialLogo'
                  : 'FinantialLogoFull'
              "
              :src="company.logo"
            ></v-img>
            <div
              id="Fields"
              class="text mx-0 mt-10"
              v-bind:class="{ 'text-expanded': expanded }"
            >
              <div class="text-left text my-auto" style="display: contents">
                <v-row
                  v-for="(
                    item, index
                  ) in company.briefing.companyAnalysis.filter(
                    (x) =>
                      x.type == 1 &&
                      gs.check_field(x.title) &&
                      gs.check_field(x.text)
                  )"
                  :key="index"
                  class="mb-2"
                  no-gutters
                >
                  <b class="analysisTitle"
                    ><span style="font-size: 1.3rem" class="dark-color">{{
                      gs.check_field(item.title)
                    }}</span></b
                  >
                  <p class="analysisText dark-color" style="font-size: 1.3rem">
                    {{ gs.check_field(item.text) }}
                  </p>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!gs.isMobile()">
        <div>
          <div v-if="read_more_visible" class="mt-4 ml-4">
            <a
              v-if="expanded == false"
              @click="expanded = true"
              style="text-decoration: underline"
              >{{ $t("read_more") }}</a
            >
            <a v-else @click="expanded = false">{{ $t("read_less") }}</a>
          </div>
        </div>
      </v-row>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
.text {
  display: block;
  max-height: 14rem;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
}
.div-limited-height {
  display: block;
  max-height: 14rem;
  overflow: hidden;
}
.text-expanded {
  max-height: 100% !important;
}
.divider {
  border-width: 1px;
}
.FinantialLogo {
  margin: 0 auto;
  display: block;
  width: 50%;
  margin-bottom: 25px;
}

.analysisTitle {
  width: 30%;
}

.analysisText {
  width: 60%;
  text-align: center;
}

.FinantialLogoFull {
  margin: 0 auto;
  display: block;
  width: 20%;
  margin-bottom: 25px;
}
@media only screen and (max-width: 600px) {
  .FinantialLogoFull {
    width: 50%;
  }
  .FinantialLogo {
    width: 80%;
  }
}

.CompanyName {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.wd100 {
  width: 100%;
  text-align: center !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "BriefingFinancial",
  data: () => ({
    gs: new GeneralServices(),
    loading: false,
    expanded: false,
    read_more_visible: false,
  }),

  async created() {
    if (
      this.company.briefing.financialOverview != null &&
      this.company.briefing.financialOverview != ""
    ) {
      this.company.briefing.financialOverview = JSON.parse(
        this.company.briefing.financialOverview
      );
    }
  },
  mounted() {
    this.check_span_size();
  },
  computed: {},
  methods: {
    check_financial_overview() {
      return (
        this.company.briefing.financialOverview != null &&
        this.company.briefing.financialOverview != "" &&
        this.company.briefing.financialOverview[this.$i18n.locale] != null &&
        this.company.briefing.financialOverview[this.$i18n.locale] != ""
      );
    },
    convertRemToPixels(rem) {
      return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
    },
    check_span_size() {
      var span_overview = document.getElementById("OverviewText");
      var span_fields = document.getElementById("Fields");
      var bool_overview = false,
        bool_fields = false;
      if (span_overview) {
        var span_height_overview = parseFloat(
          window.getComputedStyle(span_overview).height.replace("px", "")
        );
        bool_overview =
          span_height_overview >= this.convertRemToPixels(14) - 0.1;
      }
      if (span_fields) {
        var span_height_fields = parseFloat(
          window.getComputedStyle(span_fields).height.replace("px", "")
        );
        bool_fields = span_height_fields >= this.convertRemToPixels(14) - 0.1;
      }
      this.read_more_visible = bool_overview || bool_fields;
      return this.read_more_visible;
    },
  },
  props: {
    company: Object,
    signed_nda: Boolean,
  },
};
</script>
